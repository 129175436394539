<template>
  <b-dropdown
    size="sm"
    variant="link"
    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
    no-caret
    right
    no-flip
  >
    <template v-slot:button-content>
      <i class="ki ki-bold-more-ver"></i>
    </template>
    <!--begin::Navigation-->
    <div class="navi navi-hover" style="width: 250px">
      <b-dropdown-text tag="div" class="navi-item">
        <div>
          <router-link :to="'/clientfacturedetails/' + index.id">
            <div class="w-100 navi-link">
              <span class="navi-icon">
                <i class="flaticon2-file-1 text-success"></i>
              </span>
              <span class="navi-text">{{ $t('ACTION.DETAILS') }}</span>
            </div>
          </router-link>
          <router-link :to="'/clientfacturedetailtable/' + index.id">
            <div class="w-100 navi-link">
              <span class="navi-icon">
                <i class="flaticon2-file-1 text-success"></i>
              </span>
              <span class="navi-text">Fakturani kurish</span>
            </div>
          </router-link>
          <router-link :to="'/clientcompletedworks/' + index.id">
            <div class="w-100 navi-link">
              <span class="navi-icon">
                <i class="flaticon2-file-1 text-success"></i>
              </span>
              <span class="navi-text">Bajarilgan ishlarni kurish</span>
            </div>
          </router-link>
        </div>
      </b-dropdown-text>
    </div>
    <!--end::Navigation-->
  </b-dropdown>
</template>

<script>
// import VueUploadMultipleImage from "vue-upload-multiple-image";

export default {
  components: {},
  name: 'ZayavkaAction',
  data: () => ({}),
  props: {
    btnClass: String,
    index: Object,
    id: Number
  },
  computed: {},
  methods: {},
  mounted() {}
}
</script>

<style scoped>
.image-container {
  width: 100%;
}
#my-strictly-unique-vue-upload-multiple-image {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
