<template>
  <div>
    <div>
      <div class="row">
        <div class="col-12">
          <div class="card card-custom gutter-b example example-compact">
            <div
              class="
                card-header
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <div class="card-title">
                <h3 class="card-label">Hisob faktura</h3>
              </div>
            </div>
            <div class="card-body">
              <v-data-table
                :headers="headers"
                :loading="isLoading"
                loading-text="..."
                no-data-text="Malumot mavjud emas"
                :items="getData"
                :page.sync="page"
                :items-per-page="10"
                hide-default-footer
                class="elevation-1"
              >
                <template v-slot:[`item.action`]="{ item }">
                  <div style="text-align: right !important">
                    <action :index="item"></action>
                  </div> </template
              ></v-data-table>
              <!-- <div class="text-center">
                <v-pagination
                  v-model="page"
                  :length="Math.ceil(getAllCostCategories.length / 10)"
                ></v-pagination>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import action from './action'
export default {
  data() {
    return {
      page: 1,
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Mijoz', value: 'client_name' },
        { text: 'Raqami', value: 'reg_number' },
        { text: 'Shartnoma', value: 'contract' },
        { text: 'Inn', value: 'client_inn' },
        { text: 'Sana', value: 'created_at' },
        { text: 'Narxi', value: 'get_factura_amount.price' },
        { text: 'Nds', value: 'get_factura_amount.nds' },
        { text: 'Narxi(nds)', value: 'get_factura_amount.price_with_nds' },
        { text: 'Soni', value: 'get_factura_amount.count' },
        { text: '', value: 'action', sortable: false }
      ]
    }
  },
  async created() {
    await this.$store.dispatch('getClientPageFactures')
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Hisob faktura' }])
  },
  computed: {
    getData() {
      return this.$store.state.clientStore.clientPageFactures.results
    }
  },
  components: {
    action
  }
}
</script>

<style scoped>
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
}
</style>
